import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75034f91"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "crm-table__body",
  ref: "crmListRef"
}
const _hoisted_2 = {
  key: 0,
  class: "list-pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CrmTable = _resolveComponent("CrmTable")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_CrmFilter = _resolveComponent("CrmFilter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "crm-list__warp",
      style: _normalizeStyle({ visibility: _ctx.completeLoad ? 'visible' : 'hidden' })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CrmTable, _mergeProps({
          id: _ctx.id,
          data: _ctx.listDatas,
          columOptions: _ctx.columOptions,
          ref: "crmTableRef"
        }, _ctx.$attrs, { layoutHeight: _ctx.layoutHeight }), null, 16, ["id", "data", "columOptions", "layoutHeight"]),
        (_ctx.isPagination && _ctx.listDatas.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_el_pagination, {
                total: _ctx.total,
                "current-page": _ctx.pageNum,
                "page-size": _ctx.pageSize,
                "page-sizes": [10],
                layout: "total,sizes, prev, pager, next,jumper",
                background: "",
                onCurrentChange: _ctx.currentChange,
                onSizeChange: _ctx.currentSize
              }, null, 8, ["total", "current-page", "page-size", "onCurrentChange", "onSizeChange"])
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 512)
    ], 4),
    _createVNode(_component_CrmFilter, {
      options: _ctx.formatFilterOption,
      onSubmit: _ctx.queryParams,
      onLoad: _ctx.getFilterLoad,
      ref: "crmFilterRef",
      isShowFilter: _ctx.isShowFilter,
      isShowSearch: _ctx.isShowSearch
    }, null, 8, ["options", "onSubmit", "onLoad", "isShowFilter", "isShowSearch"])
  ], 64))
}