import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { ref } from 'vue';
export default () => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'hsCode',
      value: '',
      isValueNotToNull: true,
      placeholder: '请输入HS CODE',
    },
  ];
  const columnList: CrmTableOptions[] = [];
  return { filterOptions, columnList, tableRef };
};
