
import { defineComponent, ref } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import UploadExcelDialog from './components/UploadExcelDialog.vue';
import useListData from './components/useTaxrateListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading, errorMessage, exportFile, hasPermission } from '@/utils/publicMethods';
import router from '@/router';
export default defineComponent({
  name: 'TaxrateList',
  components: { CrmList, UploadExcelDialog },
  props: {},
  setup() {
    const { filterOptions, columnList, tableRef } = useListData();
    const { visible, showVisible, formType, setFormType } = useVisible();
    const rowData = ref({});
    const isShowInfoDialog = ref(false);
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const uploadUrl = process.env.VUE_APP_API_ROOT + '/malicrm/tax/importTax';
    const rowClick = () => {
      // loadPage('', {
      // id: row.id,
      // type: 1 // 1客户 2池 3回收站
      // });
    };
    // 打开新增弹窗
    const openDrawer = (formType: number): void => {
      setFormType(formType);
      showVisible();
    };
    const openInfoDialog = (): void => {
      console.log('d');
      isShowInfoDialog.value = true;
    };
    const closeInfoDialog = () => {
      isShowInfoDialog.value = false;
      console.log('closeInfoDialog');
    };
    const setRowData = (row) => {
      rowData.value = row;
    };
    // 下载表格
    const donwLoadExcel = (permission) => {
      if (hasPermission(permission)) {
        exportFile({
          type: 'POST',
          url: '/malicrm/tax/downloadTax',
        });
      } else {
        errorMessage('没有权限');
      }
    };
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable(status);
      }
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showVisible,
      closeVisible,
      setFormType,
      rowClick,
      getLoadPage,
      rowData,
      setRowData,
      openDrawer,
      openInfoDialog,
      isShowInfoDialog,
      closeInfoDialog,
      donwLoadExcel,
      uploadUrl,
    };
  },
});
