
import { httpPost } from '@/api';
import { CrmFilterOptions } from '@/types/type';
import { computed, defineComponent, nextTick, onMounted, onUnmounted, PropType, reactive, ref, toRefs, watch } from 'vue';
import CrmFilter from '@/components/CrmFilter/index.vue';
import CrmTable from '@/components/CrmTable/index.vue';
export default defineComponent({
  components: { CrmFilter, CrmTable },
  props: {
    filterOption: {
      type: Array as PropType<CrmFilterOptions[]>,
    },
    url: {
      type: String as PropType<string>,
      default: '',
    },
    customParams: {
      default: {
        type: Object,
        default: {},
      },
    },
    columOptions: {
      type: Array as PropType<any[]>,
    },
    id: {
      type: String as PropType<string>,
    },
    isPagination: {
      type: Boolean,
      default: true,
    },
    isShowFilter: {
      type: Boolean,
      default: true,
    },
    isShowSearch: {
      type: Boolean,
      default: true,
    },
    // todo props;
  },
  setup(props) {
    const paginationParams = reactive({
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
    const queryForm = ref({});
    const listDatas = ref([]);
    const crmListRef = ref(null); // 父元素对象
    const crmTableRef = ref(null); // 表格对象元素
    const crmFilterRef = ref(null);
    const tableLoad = ref(false); // 表格加载
    const filterLoad = ref(false); // 筛选项加载
    const layoutHeight = ref(610);
    const completeLoad = computed(() => {
      // 表格加载且筛选项加载加载完再显示页面，防止抖动
      return filterLoad.value && tableLoad.value;
    });
    const formatFilterOption = computed(() => {
      return props.filterOption?.filter((v) => !v.isHidden);
    });

    const getFilterLoad = (load) => {
      filterLoad.value = load;
    };
    // 获取表格信息
    const getTableDatas = (filterParams) => {
      httpPost(props.url, filterParams).then((res) => {
        if (res.code === 200) {
          if (res.result.list) {
            listDatas.value = res.result.list;
            paginationParams.total = Number(res.result.total);
          } else {
            listDatas.value = res.result;
          }
        } else {
          listDatas.value = [];
        }
        tableLoad.value = true;
      });
    };
    // 获取查询参数
    const queryParams = (filterParams) => {
      filterParams = Object.assign(
        {
          pageNum: 1,
          pageSize: paginationParams.pageSize,
        },
        props.customParams,
        filterParams
      );
      queryForm.value = filterParams;
      getTableDatas(filterParams);
    };
    // 获取条数
    const currentSize = (size) => {
      paginationParams.pageSize = size;
      const filterParams = Object.assign(queryForm.value, { pageSize: size });
      getTableDatas(filterParams);
    };
    const getQueryForm = () => {
      return queryForm.value;
    };
    // 获取页码
    const currentChange = (page) => {
      paginationParams.pageNum = page;
      const filterParams = Object.assign(queryForm.value, { pageNum: page });
      getTableDatas(filterParams);
    };
    /**
     * 描述
     * @date 2021-01-29
     * @param {any} iskeep=true 是否保持参数
     * @returns {any}
     */
    const refreshTable = (iskeep = true) => {
      let filterParams = {};
      if (iskeep) {
        filterParams = queryForm.value;
      } else {
        queryForm.value = (crmFilterRef.value as any).getRestParams();
        filterParams = Object.assign(queryForm.value, props.customParams, {
          pageNum: 1,
          pageSize: paginationParams.pageSize,
        });
      }
      getTableDatas(filterParams);
    };
    // 动态修改表格撑开高度
    const changeTableHeight = () => {
      layoutHeight.value = (crmListRef.value as any).clientHeight;

      /** 重绘表格 */
    };
    watch(completeLoad, (newVal) => {
      if (newVal === true) {
        // 这里组件没有更新
        nextTick(() => {
          changeTableHeight();
        });
      }
    });
    onMounted(() => {
      window.addEventListener('resize', changeTableHeight);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', changeTableHeight);
    });
    return {
      ...toRefs(paginationParams),
      layoutHeight,
      crmTableRef,
      crmListRef,
      crmFilterRef,
      queryForm,
      getQueryForm,
      getTableDatas,
      queryParams,
      currentChange,
      currentSize,
      listDatas,
      refreshTable,
      completeLoad,
      getFilterLoad,
      formatFilterOption,
    };
  },
});
